
import { Component, Vue } from 'vue-property-decorator';
import ApiService from '@/common/api.service';
import {convertDate} from '@/common/helpers';

function capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

@Component
export default class IncidentView extends Vue {

  private incidentId: number = 0;
  private incidentDate: string = '';
  private incidentCategory: string = '';
  private region: string = '';
  private city: string = '';
  private typeOfScene: string = '';
  private attackMethod: string = '';
  private membersRegisteredAtExchange: string = '';
  private informationSource: string = '';
  private mentionedInMediaDate: string = '';
  private description: string = '';
  private cargoCategory: string = '';
  private cargoDescription: string = '';
  private cargoAmount: string = '';
  private cargoPriceRub: string = '';
  private cargoPriceEur: string = '';
  private cargoPriceUsd: string = '';
  private country: string = '';
  private street: string = '';
  private road: string = '';
  private postalCode: string = '';
  private geoCoordinates: string = '';

  public async loadIncident(incidentId: number) {
    const resp: any = await ApiService.query('/impact/incident/' + incidentId);
    this.incidentId = resp.data.id;
    if (resp.data.incident_date) {
      this.incidentDate = `${convertDate(resp.data.incident_date)} ${resp.data.incident_time ? resp.data.incident_time :
          ''}`;
    }
    this.incidentCategory = resp.data.incident_category ?
        capitalizeFirstLetter(this.getName(resp.data.incident_category)) :
        this.$i18n.t('strings.unidentified') as string;
    this.region = resp.data.region ? this.getName(resp.data.region) : this.$i18n.t('unidentified') as string;
    this.city = resp.data[`city_${this.$i18n.locale}`];
    this.typeOfScene = resp.data.type_of_scene ? capitalizeFirstLetter(this.getName(resp.data.type_of_scene)) :
        this.$i18n.t('strings.unidentified') as string;
    this.attackMethod = resp.data.attack_method ? capitalizeFirstLetter(this.getName(resp.data.attack_method)) :
        this.$i18n.t('strings.unidentified') as string;
    this.membersRegisteredAtExchange = resp.data.members_registered_at_exchange ? 'Да' : 'Нет';
    this.informationSource = resp.data.information_source ?
        capitalizeFirstLetter(this.getName(resp.data.information_source)) :
        this.$i18n.t('strings.unidentified') as string;
    this.mentionedInMediaDate = resp.data.mentioned_in_media_date;
    this.description = resp.data[`description_${this.$i18n.locale}`];
    this.cargoCategory = resp.data.cargo_category ? capitalizeFirstLetter(this.getName(resp.data.cargo_category)) :
        this.$i18n.t('strings.unidentified') as string;
    this.cargoDescription = resp.data.cargo_description ?
        capitalizeFirstLetter(this.getName(resp.data.cargo_description)) :
        this.$i18n.t('strings.unidentified') as string;
    this.cargoAmount = resp.data.cargo_amount;
    this.cargoPriceRub = resp.data.cargo_price_rub ? resp.data.cargo_price_rub + '₽' : '';
    this.cargoPriceEur = resp.data.cargo_price_eur ? resp.data.cargo_price_eur + '€' : '';
    this.cargoPriceUsd = resp.data.cargo_price_usd ? resp.data.cargo_price_usd + '$' : '';
    this.country = resp.data.cargo_description ?
        capitalizeFirstLetter(this.getName(resp.data.country)) :
        this.$i18n.t('strings.unidentified') as string;
    this.street = resp.data.street;
    this.road = resp.data.road;
    this.postalCode = resp.data.post_code;
    this.geoCoordinates = (resp.data.latitude && resp.data.longitude) ?
        (resp.data.latitude + ' ' + resp.data.longitude) : this.$i18n.t('strings.unidentified') as string;
  }

  private getName(prop: any) {
    return prop['name_' + this.$i18n.locale];
  }

  private hideIncidentView() {
    this.$emit('hide-incident-view');
  }
}
